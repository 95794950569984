<template>
  <md-card>
    <md-card-header :data-background-color="getTheme">
      <h4
        class="title"
        v-text="
          editMode
            ? $t('worker.workerForm.formTitleEdit')
            : $t('worker.workerForm.formTitleCreate')
        "
      ></h4>
      <p
        class="category"
        v-text="
          editMode
            ? $t('worker.workerForm.formTitleEdit')
            : $t('worker.workerForm.formTitleCreate')
        "
      ></p>
    </md-card-header>

    <md-card-content>
      <div class="container-fluid">
        <v-form ref="form">
          <div class="row">
            <div class="col-lg-4 col-12">
              <div class="row d-flex align-center m-0 mb-5">
                <div class="col col-10">
                  <TGCTextField
                    :label="$t('worker.workerForm.rut')"
                    v-model="form.rut"
                    :formatters="[formatters.rut]"
                    :rules="[rules.required, rules.rut]"
                  />
                </div>
                <v-tooltip bottom open-delay="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      size="20"
                      class="col col-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline</v-icon
                    >
                  </template>
                  <span>{{ $t("tooltips.workerForm.rut") }}</span>
                </v-tooltip>
              </div>
            </div>
            <div class="col-lg-4 col-12">
              <div class="row d-flex-align-center m-0 mb-5">
                <div class="col col-10">
                  <TGCTextField
                    :label="$t('worker.workerForm.name')"
                    v-model="form.name"
                    :rules="[rules.required]"
                  />
                </div>
                <v-tooltip bottom open-delay="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      size="20"
                      class="col col-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline</v-icon
                    >
                  </template>
                  <span>{{ $t("tooltips.workerForm.name") }}</span>
                </v-tooltip>
              </div>
            </div>
            <div class="col-lg-4 col-12">
              <div class="row d-flex-align-center m-0 mb-5">
                <div class="col col-10">
                  <TGCTextField
                    :label="$t('worker.workerForm.lastName')"
                    v-model="form.lastName"
                    :rules="[rules.required]"
                  />
                </div>
                <v-tooltip bottom open-delay="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      size="20"
                      class="col col-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline</v-icon
                    >
                  </template>
                  <span>{{ $t("tooltips.workerForm.lastName") }}</span>
                </v-tooltip>
              </div>
            </div>
            <div class="col-lg-4 col-12">
              <div class="row d-flex-align-center m-0 mb-5">
                <div class="col col-10">
                  <TGCTextField
                    :label="$t('worker.workerForm.alias')"
                    v-model="form.alias"
                    :rules="[rules.required]"
                  />
                </div>
                <v-tooltip bottom open-delay="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      size="20"
                      class="col col-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline</v-icon
                    >
                  </template>
                  <span>{{ $t("tooltips.workerForm.alias") }}</span>
                </v-tooltip>
              </div>
            </div>
            <div class="col-lg-4 col-12">
              <div class="row d-flex-align-center m-0 mb-5">
                <div class="col col-10">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="form.dateOfBirth"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="form.dateOfBirth"
                        :label="$t('worker.workerForm.dateOfBirth')"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.dateOfBirth"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(form.dateOfBirth)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>
                <v-tooltip bottom open-delay="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      size="20"
                      class="col col-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline</v-icon
                    >
                  </template>
                  <span>{{ $t("tooltips.workerForm.dateOfBirth") }}</span>
                </v-tooltip>
              </div>
            </div>
            <div class="col-lg-4 col-12">
              <div class="row d-flex-align-center m-0 mb-5">
                <div class="col col-10">
                  <TGCTextField
                    :label="$t('worker.workerForm.weight')"
                    v-model="form.weight"
                    :rules="[
                      rules.required,
                      (value) => rules.min(value, 0),
                      (value) => rules.max(value, 500),
                    ]"
                    type="number"
                  />
                </div>
                <v-tooltip bottom open-delay="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      size="20"
                      class="col col-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline</v-icon
                    >
                  </template>
                  <span>{{ $t("tooltips.workerForm.weight") }}</span>
                </v-tooltip>
              </div>
            </div>
            <div class="col-lg-4 col-12">
              <div class="row d-flex-align-center m-0 mb-5">
                <div class="col col-10">
                  <TGCTextField
                    :label="$t('worker.workerForm.height')"
                    v-model="form.height"
                    :rules="[
                      rules.required,
                      (value) => rules.min(value, 0),
                      (value) => rules.max(value, 500),
                    ]"
                    type="number"
                  />
                </div>
                <v-tooltip bottom open-delay="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      size="20"
                      class="col col-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline</v-icon
                    >
                  </template>
                  <span>{{ $t("tooltips.workerForm.height") }}</span>
                </v-tooltip>
              </div>
            </div>
            <div class="col-lg-4 col-12">
              <div class="row d-flex-align-center m-0 mb-5">
                <div class="col col-10">
                  <TGCTextField
                    :label="$t('worker.workerForm.restingHeartRate')"
                    v-model="form.restingHeartRate"
                    :rules="[
                      rules.required,
                      (value) => rules.min(value, 0),
                      (value) => rules.max(value, 500),
                    ]"
                    type="number"
                  />
                </div>
                <v-tooltip bottom open-delay="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      size="20"
                      class="col col-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline</v-icon
                    >
                  </template>
                  <span>{{ $t("tooltips.workerForm.restingHeartRate") }}</span>
                </v-tooltip>
              </div>
            </div>
            <div class="col-lg-4 col-12">
              <div class="row d-flex align-center m-0 mb-5">
                <div class="col col-10">
                  <v-select
                    item-value="id"
                    item-text="name"
                    :items="departments"
                    :label="$t('worker.workerForm.department')"
                    :rules="[rules.required]"
                    v-model="form.departmentId"
                  >
                  </v-select>
                </div>
                <v-tooltip bottom open-delay="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      size="20"
                      class="col col-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline</v-icon
                    >
                  </template>
                  <span>{{ $t("tooltips.workerForm.department") }}</span>
                </v-tooltip>
              </div>
            </div>
            <div class="col-lg-4 col-12">
              <div class="row d-flex-align-center m-0 mb-5">
                <div class="col col-10">
                  <TGCTextField
                    :label="$t('worker.workerForm.contactNumber')"
                    v-model="form.contactNumber"
                    :rules="[rules.required]"
                  />
                </div>
                <v-tooltip bottom open-delay="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      size="20"
                      class="col col-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline</v-icon
                    >
                  </template>
                  <span>{{ $t("tooltips.workerForm.contactNumber") }}</span>
                </v-tooltip>
              </div>
            </div>
            <div class="md-layout-item md-size-100 text-right">
              <tgl-save-button
                class="mb-5"
                ref="saveBtn"
                :entity="entity"
                :dispatch="entity.dispatch"
                :action="editMode ? 'editado' : 'creado'"
                :redirect="entity.redirect"
                :validateForm="handleSubmit"
              ></tgl-save-button>
            </div>
          </div>
        </v-form>
      </div>
    </md-card-content>
  </md-card>
</template>
<script>
import form from "@/mixins/form";
import TglSaveButton from "@/components/UI/TglSaveButton.vue";
import TGCTextField from "@/components/UI/TGCTextField";
import { mapGetters } from "vuex";
export default {
  name: "WorkerForm",
  mixins: [form],
  props: {
    dataBackgroundColor: {
      type: String,
      default: "",
    },
  },
  components: {
    TglSaveButton,
    TGCTextField,
  },
  data() {
    return {
      form: {
        name: "",
        lastName: "",
        rut: "",
        weight: "",
        height: "",
        restingHeartRate: "",
        alias: "",
        departmentId: 0,
        dateOfBirth: null,
        credentialId: 0,
        contactNumber: "",
      },
      menu: false,
      editMode: false,
      departments: [],
      credentials: [],
    };
  },
  computed: {
    ...mapGetters(["getTheme"]),
    entity() {
      let params = {
        name: this.form.name,
        last_name: this.form.lastName,
        rut: this.form.rut,
        weight: this.form.weight,
        height: this.form.height,
        resting_heart_rate: this.form.restingHeartRate,
        alias: this.form.alias,
        department_id: this.form.departmentId,
        date_of_birth: this.form.dateOfBirth,
        credential_id: this.form.credentialId,
        contact_number: this.form.contactNumber,
      };
      return {
        name: "trabajador",
        params: this.editMode
          ? { ...params, id: this.$route.params.worker }
          : params,
        dispatch: this.editMode ? "worker/editWorker" : "worker/createWorker",
        redirect: { name: "workers" },
      };
    },
  },
  mounted: function () {
    if (this.$route.params.worker > 0) {
      this.editMode = true;
      this.getWorker();
    }
    this.getDepartments();
    this.getUnusedCredentials();
  },
  methods: {
    getUnusedCredentials() {
      this.$store
        .dispatch("worker/getUnusedCredentials")
        .then((data) => {
          let credentials = data;
          this.credentials = credentials;
          this.credentials.unshift({ id: 0, mac: "No credential" });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDepartments() {
      this.$store
        .dispatch("worker/getDepartments")
        .then((data) => {
          let departments = data.response;
          this.departments = departments;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getWorker() {
      this.$store
        .dispatch("worker/getWorker", this.$route.params.worker)
        .then((data) => {
          let worker = data.response;
          this.form.id = worker.id;
          this.form.name = worker.name;
          this.form.lastName = worker.last_name;
          this.form.rut = worker.rut;
          this.form.weight = worker.weight;
          this.form.height = worker.height;
          this.form.restingHeartRate = worker.resting_heart_rate;
          this.form.alias = worker.alias;
          this.form.departmentId = worker.department_id;
          this.form.dateOfBirth = worker.birth;
          this.form.contactNumber = worker.contact_number;
          if (worker.credential_id === null) {
            this.form.credentialId = 0;
          } else {
            this.form.credentialId = worker.credential_id;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleSubmit() {
      return this.$refs.form.validate();
    },
  },
};
</script>